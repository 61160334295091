function totalHeight(scrollableEl){
   let totalHeight = 0;
   totalHeight = scrollableEl.scrollHeight;
   return totalHeight;
}

document.addEventListener('DOMContentLoaded', function(){
   console.log('👋 Hi.');

   // find scrollable sections
   const scrollableSection = document.querySelector('.scrollable');
   const scrollableClassNames = document.querySelector('.scrollable-names');
   let scrollableSectionArray = [];
   // find scrollable section indicators
   const scrollUpIndicator = document.querySelector('.scroll-up');
   const scrollDownIndicator = document.querySelector('.scroll-down');
   const nameScrollUpIndicator = document.querySelector('.name-scroll-up');
   const nameScrollDownIndicator = document.querySelector('.name-scroll-down');

   // add scrollable section to array if it exists
   if (scrollableSection) {
      scrollableSectionArray.push(scrollableSection);
   }
   if (scrollableClassNames) {
      scrollableSectionArray.push(scrollableClassNames);
   }

   // check to make sure the page is scrollable
   if (scrollableSection || scrollableClassNames) {
      // reset scroll indicators
      let scrollableTotalHeight = totalHeight(scrollableSection);
      if (scrollableTotalHeight > scrollableSection.offsetHeight) {
         scrollDownIndicator.classList.remove('hide');
      }
      if (scrollableClassNames && (scrollableTotalHeight > scrollableClassNames.offsetHeight)) {
         nameScrollDownIndicator.classList.remove('hide');
      }
      // scroll event listener for each scrollable section
      scrollableSectionArray.forEach((element) => {
         element.addEventListener('scroll', (e) => {
            let targetEl = e.target;
            let parentHeight = totalHeight(targetEl);
            let currentScrollPos = targetEl.scrollTop;

            // check if target element is the first instance of .scrollable
            if (e.target == scrollableSection) {
               if (targetEl.offsetHeight + currentScrollPos + 100 >= parentHeight) {
                  scrollDownIndicator.classList.add('hide');
               } else {
                  scrollDownIndicator.classList.remove('hide');
               }

               if (currentScrollPos <= 50) {
                  scrollUpIndicator.classList.add('hide');
               } else {
                  scrollUpIndicator.classList.remove('hide');
               }
            }

            // check if the target element is the graduating student names element
            if (e.target == scrollableClassNames) {
               if (targetEl.offsetHeight + currentScrollPos >= parentHeight) {
                  nameScrollDownIndicator.classList.add('hide');
               } else {
                  nameScrollDownIndicator.classList.remove('hide');
               }

               if (currentScrollPos <= 10) {
                  nameScrollUpIndicator.classList.add('hide');
               } else {
                  nameScrollUpIndicator.classList.remove('hide');
               }
            }
         });
      });
      // scrollableSection.addEventListener('scroll', function(){
      //    let parentHeight = totalHeight(scrollableSection);
      //    let currentScrollPos = scrollableSection.scrollTop;
      //
      //    if (this.offsetHeight + currentScrollPos + 100 >= parentHeight) {
      //       scrollDownIndicator.classList.add('hide');
      //    } else {
      //       scrollDownIndicator.classList.remove('hide');
      //    }
      //    if (currentScrollPos <= 50) {
      //       scrollUpIndicator.classList.add('hide');
      //    } else {
      //       scrollUpIndicator.classList.remove('hide');
      //    }
      //    // console.log(`Position: ${currentScrollPos}`);
      // });
   }
});